import axios from "axios";
import authHeader from "./auth-header";

class BlacklistService {
  getBlacklist(page, client, { registration = null }) {
    const params = new URLSearchParams({
      page: page || 1,
      client_id: client,
    });
    if (registration !== null) params.append("registration", registration);
    // if(start_date !== null && start_date !== undefined) {
    //     params.set('start_date', start_date);
    // }
    // if(end_date !== null && end_date !== undefined) {
    //     params.set('end_date', end_date);
    // }
    return axios.get(
      `https://api.varsanpr.com/api/blacklist?` + params.toString(),
      { headers: authHeader() }
    );
  }

  addSingleBlacklist(client, reason, plate) {
    console.log(plate);
    return axios.put(
      `https://api.varsanpr.com/api/blacklist`,
      {
        client_id: client,
        reason: reason,
        registration: plate,
      },
      { headers: authHeader() }
    );
  }

  removeFromBlacklist(client, registration) {
    return axios.delete(`https://api.varsanpr.com/api/blacklist`, {
      headers: authHeader(),
      data: { client_id: client, registration: registration },
    });
  }
}

export default new BlacklistService();
