<template>
  <!-- Page Heading -->
  <h1 class="page-heading">{{ $t("blacklist.create_new_blacklist") }}</h1>

  <div class="row">
    <!-- Single Blacklist Entry -->
    <div class="col-12">
      <div class="card m-t-sm">
        <div class="card-body">
          <h5 class="card-title" style="margin-bottom: 25px">
            {{ $t("blacklist.single_blacklist_entry") }}
          </h5>
          <!-- <div class="card-body"> -->
          <Form
            ref="blacklistForm"
            @submit="(values) => addSingleBlacklist(values)"
            :validation-schema="schema"
          >
              <div class="row">
                <RegistrationInput />
                <div class="col-md-3 m-b-lg">
                  <label for="reason" class="form-label">
                    {{ $t("global.reason") }} <span class="text-danger">*</span>
                  </label>
                  <div class="col-sm-10">
                    <Field
                      type="text"
                      name="reason"
                      class="form-select"
                      id="reason"
                      as="select"
                      required
                    >
                      <option value="" selected>
                        {{ $t("blacklist.select_reason") }}
                      </option>
                      <option
                        v-for="reason in reasons"
                        :value="reason"
                        :key="reason"
                      >
                        {{ reason }}
                      </option>
                    </Field>
                    <ErrorMessage name="reason" class="error-feedback" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="m-b-lg col-md-4">
                  <label for="vehicleMake" class="col-sm-6 col-form-label">{{
                    $t("claims.new.vehicle_make")
                  }}</label>
                  <div class="col-sm-10">
                    <Field
                      type="text"
                      autocomplete="off"
                      name="make"
                      class="form-control"
                      id="vehicleMake"
                      placeholder="Vehicle Make"
                    />
                    <ErrorMessage name="make" class="error-feedback" />
                  </div>
                </div>
                <div class="m-b-lg col-md-4">
                  <label for="vehicleModel" class="col-sm-6 col-form-label">{{
                    $t("claims.new.vehicle_model")
                  }}</label>
                  <div class="col-sm-10">
                    <Field
                      type="text"
                      autocomplete="off"
                      name="model"
                      class="form-control"
                      id="vehicleModel"
                      placeholder="Vehicle Model"
                    />
                    <ErrorMessage name="model" class="error-feedback" />
                  </div>
                </div>
                <div class="m-b-lg col-md-4">
                  <label for="vehicleColour" class="col-sm-6 col-form-label">{{
                    $t("claims.new.vehicle_colour")
                  }}</label>
                  <div class="col-sm-10">
                    <Field
                      type="text"
                      autocomplete="off"
                      name="colour"
                      class="form-control"
                      id="vehicleColour"
                      placeholder="Vehicle Colour"
                    />
                    <ErrorMessage name="colour" class="error-feedback" />
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-3 col-xs-6 col-xxs-12 m-b-lg">
                                    <label for="reason" class="form-label"> {{ $t('global.reason') }} <span class="text-danger">*</span> </label>
                                    <Field type="text" name="reason" class="form-select" id="reason" as="select"> 
                                        <option value="" selected> {{$t('blacklist.select_reason')}} </option>
                                        <option v-for="reason in reasons" :value="reason" :key="reason"> {{reason}} </option>
                                    </Field>
                                    <ErrorMessage name="reason" class="error-feedback"/>
                                </div> -->
              <div class="">
                <button class="btn bg-danger text-white" type="submit">
                  <i class="material-icons" v-show="!addingSingleBlacklist">
                    add
                  </i>
                  <span
                    v-show="addingSingleBlacklist"
                    class="spinner-border spinner-border-sm m-r-xxs"
                  ></span>
                  {{ $t("blacklist.add_to_blacklist") }}
                </button>
              </div>
          </Form>

          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import MessageCard from "../../components/MessageCard.vue";
import * as yup from "yup";
import BlacklistService from "../../services/blacklist.service";
import ExternalService from "../../services/external.service";
import RegistrationInput from "../../components/RegistrationInput.vue";
// import csvsplitstream from 'csvSplitStream';

export default {
  name: "NewBlacklist",
  components: { Form, Field, ErrorMessage, MessageCard, RegistrationInput },
  data() {
    const schema = yup.object().shape({
      registration: yup
        .string()
        .min(3, "Must be at least 3 characters!")
        .max(12, "Must not be longer than 12 characters"),
      reason: yup.string().required(),
    });
    return {
      schema,
      reasons: [
        "Drive Off",
        "No Means Of Payment",
        "Fly Tipping",
        "Aggressive",
        "Theft",
        "Plate Mismatch",
      ],
      errorMsg: "",
      addingSingleBlacklist: false,
    };
  },
  methods: {
    getMMC(val) {
      ExternalService.getMMC(val.target.value.replace(/\s/g, "").toUpperCase())
        .then((response) => {
          this.$refs.blacklistForm.setFieldValue(
            "make",
            response.data.vehicle.make
          );
          this.$refs.blacklistForm.setFieldValue(
            "model",
            response.data.vehicle.model
          );
          this.$refs.blacklistForm.setFieldValue(
            "colour",
            response.data.vehicle.colour
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addSingleBlacklist(values) {
      this.addingSingleBlacklist = true;
      BlacklistService.addSingleBlacklist(
        this.$store.state.auth.user.selectedClient,
        values.reason,
        values.registration
      )
        .then((response) => {
          console.log(response);
          this.$success(this.$t("blacklist.success_message"));
          this.addingSingleBlacklist = false;
          this.$router.push("/blacklist");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {
              //Unprocessable Entity
              this.errorMsg = "";
              for (let key in error.response.data.errors) {
                error.response.data.errors[key].forEach((msg) => {
                  this.errorMsg += `<br/>${msg}`;
                });
              }
              this.error = true;
            }
            if (error.response.status == 401) {
              this.errorMsg = error.response.data.message;
              this.error = true;
            }
            this.addingSingleBlacklist = false;
          }
        });
    },
  },
};
</script>

<style>
#csv-label {
  font-size: 16px;
  font-weight: 100;
}

.csv-input {
  width: fit-content;
  background-color: #2b2b38;
  border-radius: 5px 0 0 5px;
}

.csv-input input {
  padding: 10px 0;
}

.csv-input button {
  padding: 12px 10px;
  border-radius: 0 5px 5px 0;
  border: none;
}

.card-title {
  font-weight: 400 !important;
}

.no-of-entries {
  font-size: 16px;
  padding: 7px;
}

/* .card {
    background-color: var(--bg-color-tertiary);
} */

/* .form-control, .form-select {
    background-color: var(--bg-color-secondary);
}

.custom-file-label {
    color: var(--font-color-secondary);
} */

/* #bl-message-card {
    background-color: #2a2a3a;
} */

/* .btn.bg-danger.text-white {
    pointer-events: none;
} */

@media (max-width: 2559px) {
  .col-md-3 {
    width: 40%;
  }
}
</style>
